import React from "react";

import Background from "../assets/img/accueil/main.jpg";
import Logo from "../assets/logo/terracotta/mainLogoTeracotta.svg";

const Header = () => {
  return (
    <>
      <div
        className="header"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top",
          backgroundSize: "70%",
        }}
      >
        <div className="header__container">
          <div className="header__container__logo">
            <img src={Logo} alt="" />
          </div>
          <div className="header__container__text">
            <h2>Construisons ensemble vos souvenirs</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
