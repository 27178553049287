import React from "react";

import Maternity1 from "../../assets/img/maternity/maternity1.jpg";
import Maternity2 from "../../assets/img/maternity/maternity2.jpg";
import Maternity3 from "../../assets/img/maternity/maternity3.jpg";
import Maternity4 from "../../assets/img/maternity/maternity4.jpg";
import Maternity5 from "../../assets/img/maternity/maternity5.jpg";
import Maternity6 from "../../assets/img/maternity/maternity6.jpg";
import Maternity7 from "../../assets/img/maternity/maternity7.jpg";
import Maternity8 from "../../assets/img/maternity/maternity8.jpg";

import Carousel from "./Carousel";

const MaternityPictures = [
  Maternity1,
  Maternity2,
  Maternity3,
  Maternity4,
  Maternity5,
  Maternity6,
  Maternity7,
  Maternity8,
];

const Maternity = () => {
  return (
    <div>
      <Carousel images={MaternityPictures} />
    </div>
  );
};

export default Maternity;
