import React from "react";

import Price1 from "../../assets/img/prix/price5.png";
import Price2 from "../../assets/img/prix/price6.png";

const PricesCards = () => {
  return (
    <>
      <div className="pricesCards">
        <div className="pricesCards__card pricesCards--card1">
          <img src={Price1} alt="" />
        </div>
        <div className="pricesCards__card pricesCards--card2">
          <img src={Price2} alt="" />
        </div>
      </div>
    </>
  );
};

export default PricesCards;
