import React, { useState } from "react";

const FORM_ENDPOINT =
  "https://public.herotofu.com/v1/80916710-9109-11ee-9fc3-0f51c88dc991";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const data = {};

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        data[inputs[i].name] = inputs[i].value;
      }
    }

    fetch(FORM_ENDPOINT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Form response was not ok");
        }

        setSubmitted(true);
      })
      .catch((err) => {
        // Submit the form manually
        e.target.submit();
      });
  };

  if (submitted) {
    return (
      <>
        <div className="submitted">
          <div className="submitted__title">
            <p>Merci</p>
          </div>
          <div className="submitted__text">
            <p>Je vous contacte au plus vite</p>
          </div>
        </div>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
      className="form"
    >
      <div className="form__title">
        <h3>Contact</h3>
      </div>
      <div className="form__name">
        <input
          type="text"
          placeholder="Votre nom"
          name="name"
          className="name-input"
          required
        />
      </div>
      <div className="form__email">
        <input
          type="email"
          placeholder="Email"
          name="email"
          className="email-input"
          required
        />
      </div>
      <div className="form__text">
        <textarea
          placeholder="Votre message"
          name="message"
          className="text-input"
          required
        />
      </div>
      <div className="form__submit">
        <button className="submit__button" type="submit">
          soumettre
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
