import React, { useState } from "react";

import { Link } from "react-router-dom";

const About1 = () => {
  const [show, setShow] = useState(false);

  function showSwitch() {
    return setShow(!show);
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <section className="about1">
        <section className="about1__about">
          <div className="about1__about__title">
            <h1>À propos du studio Élodie Civiletti Photographie</h1>
            <h2>Élodie Civiletti, photographe famille à Lavérune</h2>
          </div>
          <div className="about1__about__text">
            <p>
              Bienvenue chez Élodie Civiletti Photographie ! J’ai le plaisir de
              vous accueillir dans mon studio photo situé à Lavérune, dans
              l’Hérault (34). Ce charmant village, bien qu’à seulement 20
              minutes du centre de Montpellier, échappe à l’agitation de la
              ville et aux contraintes urbaines (circulation, parking…).
            </p>
            <p>
              C’est dans la zone artisanale « Descartes » de Lavérune que mon
              studio a ouvert ses portes, le 12 octobre 2023. Après plus de 13
              ans de carrière dans les ressources humaines, j’ai décidé de
              sauter le pas et de réaliser mon rêve : exercer la photographie
              professionnellement. J’ai suivi plusieurs formations avant de me
              spécialiser dans la photographie de famille. Pourquoi ce choix ?
              Parce que capter l’essence de vos liens familiaux et immortaliser
              des moments de tendresse avec vos proches me semble être la plus
              belle chose qu’une photo puisse accomplir. Formée à la
              manipulation des nouveau-nés dans ce contexte, je matérialise le
              souvenir des grands événements de votre vie. Grossesse, naissance,
              maternité… l’arrivée de votre bout de chou mérite d’être capturée
              avec délicatesse et sensibilité. Et, parce que la photo est aussi
              un moyen de s’affirmer, je réalise des portraits individuels pour
              booster votre confiance en vous, ainsi que des portraits
              corporate. Donnez la meilleure image possible à votre entreprise
              en optant pour des photos professionnelles !
            </p>
            <p>
              Je défends les valeurs du Made in France et privilégie donc, pour
              vos séances newborn posing, des créations 100% françaises. D’autre
              part, dans l’optique de vous offrir les meilleurs clichés, je
              réalise également des shootings en extérieur. Notre belle région
              regorge de cadres sublimes : domaines viticoles, abords fleuris de
              la Mosson, garrigues… Le climat méditerranéen de cette partie de
              l’Occitanie favorise les espaces naturels propices aux images.
              <br />
              Découvrez ci-dessous toutes mes prestations ainsi que les services
              associés !
            </p>
          </div>
        </section>
        <section className="about1__prestations">
          <div className="about1__prestations__texts">
            <h3 className="about1__prestations__texts__title">
              Naissance, couple, portrait… des prestations adaptées à vos
              besoins
            </h3>
            <p className="about1__prestation__texts__text">
              Envie d’immortaliser l’aventure de votre grossesse, la naissance
              de votre enfant, ou même un moment de complicité avec votre animal
              de compagnie ? Le studio Élodie Civiletti Photographie vous offre
              des prestations sur mesure en fonction de vos demandes.
            </p>
          </div>

          <div className="about1__prestation1">
            <span className="about1__prestation1__prestations__prestation">
              🤰🏻 Grossesse : pour capturer la symbiose de votre corps avec votre
              enfant à naître, je vous accueille dans le confort et l’intimité
              de mon studio.
            </span>
            <span className="about1__prestation1__prestations__prestation">
              👶🏻 Naissance : formée à la manipulation des nouveau-nés, vous
              pouvez me faire confiance pour réaliser avec soin les photos
              pleines de douceur de votre enfant.
            </span>
            <span className="about1__prestation1__prestations__prestation">
              👩🏼‍🤝‍👨🏽 Couple : choisissez la photographie comme moyen de raconter
              votre histoire d’amour avec votre conjoint ou votre conjointe !
            </span>
            <span className="about1__prestation1__prestations__prestation">
              👨‍👩‍👧‍👦 Famille : réunissez votre famille pour partager ensemble un
              shooting photo inoubliable… et des images tout aussi mémorables !
            </span>
            <span className="about1__prestation1__prestations__prestation">
              🐶 Harmonie animale : captez l’essence de votre relation avec
              votre animal de compagnie au cours d’une séance photo originale.
            </span>
            <span className="about1__prestation1__prestations__prestation">
              🧑🏻‍🦰 Portrait individuel : révélez-vous à travers des images qui
              exacerbent votre confiance en vous et découvrez-vous comme vous ne
              vous étiez jamais vu !
            </span>
            <span className="about1__prestation1__prestations__prestation">
              💼 Portrait corporate : je vous propose un shooting photo sur
              mesure pour dynamiser l’image de votre entreprise dans le respect
              de ses valeurs.
            </span>
            <span className="about1__prestation1__prestations__prestation">
              🌿 Extérieur : tirez profit de notre merveilleux département de
              l’Hérault en optant pour une séance photo en extérieur.
            </span>
          </div>

          <div className="about1__prestations__texts">
            <p className="about1__prestation__texts__text">
              Pour votre confort, une professionnelle de la mise en beauté
              (coiffure et maquillage) est à votre disposition lors de votre
              séance photo. Vos images vous sont ensuite délivrées via une
              galerie en ligne sécurisée, où vous pouvez choisir vos fichiers
              numériques HD et les tirages papier que vous souhaitez réaliser.
              Il est également possible de les récupérer sur une clé USB.
            </p>
            <p className="about1__prestation__texts__text">
              Le studio Élodie Civiletti Photographie est situé au 1 Rue du
              Perpignan, 34880 Lavérune. Vous pouvez y venir en voiture, une
              place de parking vous est réservée ! Profitez de votre visite pour
              découvrir, dans les environs du village, le château des Evêques
              classé monument historique et le château de l’Engarran, un
              important domaine viticole.
            </p>
            <p className="about1__prestation__texts__text">
              Pour consulter les prestations détaillées et leurs tarifs, c’est{" "}
              <span onClick={scrollToTop}>
                <Link
                  onClick={() => showSwitch()}
                  to="/prestations"
                  className="links__link"
                >
                  ici
                </Link>
              </span>{" "}
              !
            </p>
          </div>
        </section>
        <section className="about1__contact">
          <h3 className="about1__contact__title">Alors, n’attendez plus…</h3>
          <p className="about1__contact__phone">
            📞Réservez dès maintenant votre séance photo au :{" "}
            <a href="tel:06.79.99.98.49">06.79.99.98.49</a>
          </p>
          <p className="about1__contact__email">
            📧Contactez-moi par mail à l’adresse :{" "}
            <a href="mailto:elodie.civiletti@gmail.com">
              elodie.civiletti@gmail.com
            </a>
          </p>
        </section>
      </section>
    </>
  );
};

export default About1;
