import React from 'react';

import About1 from "../components/About1";

const About = () => {
    return (
        <>
            <About1 />
        </>
    );
};

export default About;