import React from "react";
import ContactForm from "../components/ContactForm";

import Image from "../assets/img/accueil/contactImg.jpg";

const Contact = () => {
  return (
    <>
      <div className="contact">
        <div className="contact__image">
          <img src={Image} alt="" />
        </div>
        <ContactForm />
      </div>
    </>
  );
};

export default Contact;
