import React from "react";

import Portrait1 from "../../assets/img/portrait/portrait1.jpg";
import Portrait2 from "../../assets/img/portrait/portrait2.jpg";
import Portrait3 from "../../assets/img/portrait/portrait3.jpg";
import Portrait4 from "../../assets/img/portrait/portrait4.jpg";
import Portrait5 from "../../assets/img/portrait/portrait5.jpg";
import Portrait6 from "../../assets/img/portrait/portrait6.jpg";
import Portrait7 from "../../assets/img/portrait/portrait7.jpg";
import Portrait8 from "../../assets/img/portrait/portrait8.jpg";
import Portrait9 from "../../assets/img/portrait/portrait9.jpg";
import Portrait10 from "../../assets/img/portrait/portrait10.jpg";
import Portrait11 from "../../assets/img/portrait/portrait11.jpg";
import Portrait12 from "../../assets/img/portrait/portrait12.jpg";

import Carousel from "./Carousel";

const PortraitPictures = [
  Portrait1,
  Portrait2,
  Portrait3,
  Portrait4,
  Portrait5,
  Portrait6,
  Portrait7,
  Portrait8,
  Portrait9,
  Portrait10,
  Portrait11,
  Portrait12,
];

const Portrait = () => {
  return (
    <div>
      <Carousel images={PortraitPictures} />
    </div>
  );
};

export default Portrait;
