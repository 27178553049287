import React, { useState } from "react";

import { Link } from "react-router-dom";
import image from "../assets/img/accueil/studio.jpg";

const Home1 = () => {
  const [show, setShow] = useState(false);

  function showSwitch() {
    return setShow(!show);
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="home1">
        <div className="home1__container">
          <div className="home1__container__image">
            <img src={image} alt="" />
          </div>
          <div className="home1__container__text">
            <p>
              Bienvenue sur Élodie Civiletti Photographie ! Photographe
              passionnée spécialisée dans la photographie de famille, je vous
              accueille dans mon studio de Lavérune, près de Montpellier, pour
              une expérience unique devant mon objectif. Je capture les moments
              importants de votre vie pour vous laisser des souvenirs
              inoubliables : grossesse, naissance, couple, famille... Je vous
              accompagne également dans votre réalisation personnelle avec des
              portraits individuels et corporate pour votre entreprise.
            </p>
            <div className="home1__container__text__links">
              <div onClick={scrollToTop}>
                <Link
                  onClick={() => showSwitch()}
                  to="/prestations"
                  className="home1__container__text__links__link"
                >
                  Consultez ici mes prestations et tarifs
                </Link>
              </div>
              <div onClick={scrollToTop}>
                <Link
                  onClick={() => showSwitch()}
                  to="/portfolio"
                  className="home1__container__text__links__link"
                >
                  Explorez mon portfolio et découvrez mon travail
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home1;
