import React, { useState } from "react";

import { Link } from "react-router-dom";
import { FaInstagram } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

import Logo from "../assets/logo/terracotta/navLogoTeracotta.svg";

const Navbar = () => {
  const [show, setShow] = useState(false);

  function showSwitch() {
    return setShow(!show);
  }

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="navbar">
        <div className="navbar__logo">
          <img src={Logo} alt="" />
        </div>
        <div className={show ? "links active" : "links"}>
          <div onClick={scrollToTop}>
            <Link onClick={() => showSwitch()} to="/" className="links__link">
              accueil
            </Link>
          </div>
          <div onClick={scrollToTop}>
            <Link
              onClick={() => showSwitch()}
              to="/about"
              className="links__link"
            >
              à propos
            </Link>
          </div>
          <div onClick={scrollToTop}>
            <Link
              onClick={() => showSwitch()}
              to="/portfolio"
              className="links__link"
            >
              portfolio
            </Link>
          </div>
          <div onClick={scrollToTop}>
            <Link
              onClick={() => showSwitch()}
              to="/prestations"
              className="links__link"
            >
              prestations
            </Link>
          </div>
          <div onClick={scrollToTop}>
            <Link
              onClick={() => showSwitch()}
              to="/contact"
              className="links__link"
            >
              contact
            </Link>
          </div>
          <a
            href="https://www.instagram.com/elodieciviletti.photographie/?ref=xlLbWXw0&hl=am-et"
            className="links__link"
            target="blank"
          >
            <FaInstagram size={40} />
          </a>
          <a
            href="https://www.facebook.com/elodieciviletti.photographie"
            className="links__link"
            target="blank"
          >
            <FaFacebook size={40} />
          </a>
        </div>
        <div
          onClick={() => showSwitch()}
          className={show ? "burger active" : "burger"}
        >
          <span className="burger__bars bar1"></span>
          <span className="burger__bars bar2"></span>
          <span className="burger__bars bar3"></span>
        </div>
      </div>
    </>
  );
};

export default Navbar;
