import React from "react";
import PrestationsCard from "../components/prestations/PrestationsCard";
import PricesCards from "../components/prestations/PricesCards";

const Prestations = () => {
  return (
    <>
      <div className="prestations">
        <PrestationsCard />
        <PricesCards />
      </div>
    </>
  );
};

export default Prestations;
