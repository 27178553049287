import React from "react";
import Header from "../components/Header";
import Home1 from "../components/Home1";

const Home = () => {
  return (
    <>
      <Header />
      <Home1 />
    </>
  );
};

export default Home;
