import React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";

import Home from "./views/Home";
import About from "./views/About";
import Portfolio from "./views/Portfolio";
import Prestations from "./views/Prestations";
import Contact from "./views/Contact";
import Footer from "./components/Footer";
import MentionsLégales from "./views/MentionsLégales";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/prestations" element={<Prestations />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/mentionsLégales" element={<MentionsLégales />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;
