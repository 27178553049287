import React from "react";

import Baby from "../../assets/img/baby/baby5.jpg";
import Maternity from "../../assets/img/maternity/maternity2.jpg";
import Couple from "../../assets/img/couple/couple1.jpg";
import Family from "../../assets/img/family/family5.jpg";
import Portrait from "../../assets/img/portrait/portrait3.jpg";
import Corporate from "../../assets/img/corporate/corpo1.jpg";
import Animals from "../../assets/img/animals/animal1.jpg";

const PrestationsCard = () => {
  return (
    <>
      <div className="prestationsCards">
        <div className="prestationsCards__container">
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Baby} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Naissance</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>
                Formée à la manipulation des nouveaux nés pour immortaliser avec
                soin l’essentiel dans la douceur.
              </p>
            </div>
          </div>
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Maternity} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Grossesse</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>
                Vivez une expérience unique autour de votre grossesse dans la
                bulle de mon studio.
              </p>
            </div>
          </div>
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Couple} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Couple</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>Capturer et raconter votre histoire avec passion.</p>
            </div>
          </div>
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Family} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Famille</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>Offrir des souvenirs de votre vie de famille.</p>
            </div>
          </div>
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Portrait} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Portrait</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>
                Révélez l'essence de votre être à travers un portrait
                individuel, une image qui transcende le regard pour capturer
                votre singularité intemporelle.
              </p>
            </div>
          </div>
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Corporate} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Corporate</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>
                Propulsez l’image de votre entreprise avec des clichés
                professionnels capturant son essence et son dynamisme lors d’un
                shooting photo sur mesure.
              </p>
            </div>
          </div>
          <div className="prestationsCards__container__card">
            <div className="prestationsCards__container__card__image">
              <img src={Animals} alt="" />
            </div>
            <div className="prestationsCards__container__card__title">
              <h4>Harmonie Animale</h4>
            </div>
            <div className="prestationsCards__container__card__text">
              <p>
              Immortalisez vos précieux moments avec votre animal de compagnie lors d’un shooting photo, où complicité et amour se transforment en souvenirs éternels.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrestationsCard;
