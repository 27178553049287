import React from "react";

import Baby from "../components/carousels/Baby";
import Maternity from "../components/carousels/Maternity";
import Couple from "../components/carousels/Couple";
import Family from "../components/carousels/Family";
import Portrait from "../components/carousels/Portrait";
import Corporate from "../components/carousels/Corporate";
import Animals from "../components/carousels/Animals";

const Portfolio = () => {
  return (
    <div className="portfolio">
      <div className="portfolio__container">
        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Naissance</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              Pensez à prendre rdv aux alentours de votre 6eme mois de grossesse
              pour réserver la séance de votre nouveau-né.
            </p>
            <p>
              Dans l’idéal, la séance doit être programmée entre le 5ème et le
              14ème jour.
            </p>
            <p>
              Lors des séances vous pourrez bénéficier d’accessoires créés par
              des artisans locaux.
            </p>
          </div>
          <Baby />
        </div>

        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Grossesse</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              Lorsque vous êtes enceinte c’est l’occasion de faire des portraits
              inédits, seule ou accompagnée de votre partenaire et/ou de la
              fratrie.
            </p>
            <p>
              L’idéal est de choisir le moment où vos rondeurs sont bien
              visibles, sans pour autant attendre les derniers jours.
            </p>
          </div>
          <Maternity />
        </div>

        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Couple</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              Les selfies que vous prenez à 2 avec votre smartphone se
              ressemblent tous et vous rêvez d’avoir de belles photos en
              amoureux ?
            </p>
            <p>
              Venez partager un moment de complicité hors du temps dans la bulle
              de mon studio, célébrons votre amour avec des clichés qui vous
              ressemblent.
            </p>
            <p>
              Quelque chose à célébrer comme un anniversaire, une demande en
              mariage, une séance photos en couple, … cela est une très belle
              expérience avec des rires, des moments doux, du partage…
            </p>
          </div>
          <Couple />
        </div>
        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Famille</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              Vous souhaitez immortaliser un moment de vie en famille, avec vos
              enfants, entre frères et sœurs ou inter générationnel… je suis là
              pour vous accompagner en vous garantissant un véritable moment de
              plaisir avec une séance à votre image.
            </p>
          </div>
          <Family />
        </div>
        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Portrait</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              Rejoignez moi pour une expérience photographique unique, venez
              explorer les bienfaits d'un portrait au-delà du simple souvenir.
              Bien plus qu'une simple image, un portrait individuel capturé avec
              soin peut être un catalyseur de confiance en soi.
            </p>
            <p>
              Chaque séance est une aventure visant à mettre en lumière votre
              essence et révéler votre force intérieure.
            </p>
            <p>
              Pour les besoins professionnels, mon expertise s'étend au domaine
              corporate. Mon but est de créer des portraits qui reflètent votre
              personnalité. Chaque image est soigneusement composée pour
              transmettre une image forte et authentique de votre entreprise.
            </p>
            <p>
              Que ce soit pour un portrait personnel ou pour renforcer l'image
              de votre entreprise, je suis là pour capturer l'essence de chaque
              instant avec créativité et professionnalisme.
            </p>
          </div>
          <Portrait />
        </div>
        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Corporate</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              À la recherche de clichés professionnels pour votre entreprise ?
              Faites bonne impression avec des photos de qualité professionnelle
              qui mettent en valeur votre entreprise et son équipe
            </p>
            <p>
              Contactez-moi dès aujourd'hui pour réserver votre séance
              corporate.
            </p>
          </div>
          <Corporate />
        </div>
        <div className="portfolio__container__section">
          <div className="portfolio__container__section__title">
            <h3>Harmonie Animale</h3>
          </div>
          <div className="portfolio__container__section__text">
            <p>
              Parce que les animaux de compagnie sont bien plus que de simples
              compagnons, mes séances photos captivent l'essence unique de votre
              relation spéciale.
            </p>
            <p>
              Que ce soit avec votre fidèle chien, votre chat ou tout autre
              compagnon à fourrure, nous créons des souvenirs intemporels qui
              célèbrent le lien inestimable entre vous et votre animal de
              compagnie.
            </p>
          </div>
          <Animals />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
